import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import BackgroundImage from '../images/404/404-page-background-image.png';

const Wrapper = styled.section`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: url(${BackgroundImage}) right top/contain no-repeat #0e1828;
    color: #fff;
    .outer-wrapper {
        display: block;
        flex: 0 1 auto;
        width: 100%;
        max-width: 860px;
        margin-left: auto;
        margin-right: auto;
        .inner-wrapper {
            display: block;
            width: 100%;
            padding: 4rem;
            text-align: center;
            h1 {
                font-family: 'Poppins';
                font-size: 0;
                line-height: 0;
                font-weight: 600;
                margin-bottom: 0;
            }
            svg {
                display: block;
                width: 100%;
                height: auto;
                font-size: 0;
            }
            p {
                font-size: 1.6rem;
                line-height: 1.875;
                font-weight: 600;
                margin: 2rem auto;
                max-width: 360px;
            }
            .button {
                display: inline-block;
                text-decoration: none;
                color: #fff;
                background: #e2246e;
                font-family: 'Poppins';
                font-size: 1.1rem;
                line-height: 2;
                font-weight: 600;
                letter-spacing: 0.3em;
                text-transform: uppercase;
                border-radius: 25px;
                padding: 1.5rem 2.5rem;
                svg {
                    display: inline-block;
                    width: auto;
                    height: 2.2rem;
                    margin-left: 2rem;
                    vertical-align: middle;
                }
            }
        }
    }
`;

const NotFoundPage = ({ transitionStatus }) => {
    useEffect(() => {
        console.log('404 Page', transitionStatus);
    }, [transitionStatus]);

    return (
        <Layout hideFooter={true}>
            <Seo title="404: Not found" />
            <Wrapper>
                <div className="outer-wrapper">
                    <div className="inner-wrapper">
                        <h1>404</h1>
                        <svg viewBox="0 0 860 311" fill="none">
                            <path
                                d="M0 248.708V198.01L146.462 0.848633H211.894V195.843H251.759V248.708H211.894V310.24H150.362V248.708H0ZM155.129 72.3466L62.3982 195.843H155.129V72.3466Z"
                                fill="white"
                            />
                            <path
                                d="M608.24 248.708V198.01L754.703 0.848633H820.134V195.843H860V248.708H820.134V310.24H758.603V248.708H608.24ZM763.369 72.3466L670.638 195.843H763.369V72.3466Z"
                                fill="white"
                            />
                            <path
                                d="M317.601 35.2175C308.402 57.7271 304.027 69.8218 304.027 83.5964C304.027 109.802 301.56 137.463 327.135 150.005C346.093 101.29 382.437 61.4227 428.429 37.7932C414.071 13.2678 414.071 13.2678 352.936 16.5155C341.382 21.331 329.603 27.4903 317.601 35.2175Z"
                                fill="#5FDDEB"
                            />
                            <path
                                d="M352.936 16.5158C379.97 18.8676 405.433 26.2588 428.541 37.7936C476.44 61.535 514.13 102.971 533.2 153.365C577.285 141.831 557.317 100.507 557.317 73.5178C557.317 59.8552 546.212 49.6643 543.744 36.7857C543.632 36.5617 543.52 36.4497 543.52 36.4497C543.52 36.4497 528.601 25.2509 503.474 15.5079C468.587 1.84537 414.071 -9.01747 352.936 16.5158Z"
                                fill="url(#paint0_linear)"
                            />
                            <path
                                d="M317.601 35.2178C314.797 38.8014 235.489 139.703 316.255 266.249C316.367 266.473 316.479 266.697 316.704 266.921C316.704 266.921 331.735 278.568 357.199 288.647C404.312 299.846 461.297 286.967 432.131 266.361C384.121 242.396 346.206 200.96 327.36 150.23C318.611 126.824 313.899 101.627 313.899 75.4215C313.787 61.759 315.133 48.2084 317.601 35.2178Z"
                                fill="url(#paint1_linear)"
                            />
                            <path
                                d="M431.907 266.249C409.024 278.008 383.897 285.623 357.199 288.423C391.525 301.973 444.807 312.724 505.157 287.415C517.609 282.152 530.397 275.432 543.409 266.809C543.409 266.809 543.409 266.809 543.409 266.697C622.941 146.87 548.569 43.3929 543.633 36.7856C546.101 49.6643 547.335 63.1028 547.335 76.6534C547.335 103.643 542.287 129.512 533.201 153.253C514.356 202.304 478.123 242.508 431.907 266.249Z"
                                fill="#E2246E"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear"
                                    x1="561.369"
                                    y1="80.4123"
                                    x2="351.349"
                                    y2="71.8531"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop offset="0.1" stopColor="#874C98" />
                                    <stop offset="0.12" stopColor="#8E4A97" />
                                    <stop offset="0.22" stopColor="#A94192" />
                                    <stop offset="0.33" stopColor="#BC3B8F" />
                                    <stop offset="0.43" stopColor="#C7378D" />
                                    <stop offset="0.54" stopColor="#CB368C" />
                                    <stop offset="0.75" stopColor="#E32C59" />
                                    <stop offset="1" stopColor="#E6273C" />
                                </linearGradient>
                                <linearGradient
                                    id="paint1_linear"
                                    x1="237.474"
                                    y1="113.538"
                                    x2="399.887"
                                    y2="192.826"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#9462A5" />
                                    <stop offset="0.05" stopColor="#7769AD" />
                                    <stop offset="0.1" stopColor="#5C70B3" />
                                    <stop offset="0.16" stopColor="#4974B8" />
                                    <stop offset="0.21" stopColor="#3E77BB" />
                                    <stop offset="0.27" stopColor="#3A78BC" />
                                    <stop offset="0.67" stopColor="#2BB4E9" />
                                    <stop offset="1" stopColor="#67C1BC" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <p>
                            Whoops, that page doesn’t exist or has been removed, let’s get you back
                            on track.
                        </p>
                        <Link className="button" to="/">
                            Home
                            <svg
                                width="23"
                                height="23"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.9805 10.2014L22.25 8.78773L18.0353 5.57042V1.76816H15.21V3.42556L11.2732 0.354492L0.25 8.73898L2.47316 10.1039C2.38053 10.5426 2.33421 10.9813 2.28789 11.4688C2.14895 12.9312 2.19526 14.2961 2.51947 15.7586C2.79737 17.221 3.26053 18.5371 3.90895 19.7558C4.65 20.3408 5.43737 20.877 6.27105 21.267C7.01211 21.6082 7.75316 21.9007 8.54053 22.0957V15.856H14.1447V22.0957C14.8395 21.9007 15.7195 21.6082 16.4605 21.2182C17.2016 20.8282 17.9426 20.3895 18.5911 19.8533C19.2395 18.6346 19.6563 17.3185 19.9342 15.856C20.1658 14.3936 20.3047 12.98 20.1658 11.5663C20.1195 11.0788 20.0732 10.6401 19.9805 10.2014Z"
                                    fill="white"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>
            </Wrapper>
        </Layout>
    );
};

export default NotFoundPage;
